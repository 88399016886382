




























































































































































































import Vue from "vue";
import { accounts } from "@/code/api.accounts";
import * as models from "@/models";
import { G, rules } from "@/globals";
import { Tracking } from "@/code/tracking";
import { jobs } from "@/code/api.jobs.seekers";

export default {
  name: "Accounts_Create",
  components: {},
  data: () => ({
    usrFirstname: null,
    usrEmail: null,
    saving: false,
    formValid: false,
    rules: {
      required: rules.required,
      maxCounter: rules.maxCounter,
      email: rules.email
    },
    saved: false,
    loginError: "",
    preview: {
      recentJps: [],
      expanded: true
    }
  }),
  mounted: function() {
    G.setSeekerMode(this.$route.query.r != "1", this, true);
    Tracking.accounts.signupStart();
    G.data.customTitle = "Create";
  },
  created() {
    document.addEventListener("focusin", this.focusChanged);
    jobs.seekers.posts.getPublished_Paged__NoUser(10, 0, 3).then(jpxPaged => {
      this.preview.recentJps = jpxPaged.items;
    });
  },
  beforeDestroy() {
    document.removeEventListener("focusin", this.focusChanged);
  },
  methods: {
    focusChanged(event) {
      const el = event.target;
      // console.log(el);
    },

    tests: function() {
      // console.log("aa");
    },
    register: function() {
      let form = <any>this.$refs.form;
      let valid = form.validate();
      if (!valid) return;
      G.showLoading("Creating your account, please wait...", true);
      this.loginError = "";

      accounts
        .quickRegister(this.usrEmail, this.usrFirstname, this.isSeekerMode)
        .then(lgn => {
          this.saved = true;
          Tracking.userId.set(lgn.id);
          Tracking.accounts.signupFinish();
          window.location.href = window.location.href + "#created";
          G.data.customTitle = "Created";
        })
        .catch(err => {
          G.log.error("Account.Create.quickRegister", {
            error: err,
            email: this.usrEmail,
            isSeeker: G.data.isSeekerMode
          });
          this.loginError = err;
        })
        .finally(() => {
          G.hideLoading();
        });
    }
  },
  computed: {
    isSeekerMode: {
      get: function() {
        return G.data.isSeekerMode;
      },
      set: function(newValue: boolean) {
        G.setSeekerMode(newValue, this, false);
      }
    }
  }
};
