

















































import Vue from "vue";
import { accounts } from "@/code/api.accounts";
import * as models from "@/models";
import { G, rules } from "@/globals";
import { Tracking } from "@/code/tracking";

export default {
  name: "Accounts_ResetPassword",
  components: {},
  data: () => ({
    showPassword: false,
    newPassword: null,
    saving: false,
    formValid: false,
    rules: {
      required: rules.required,
      passwordLength: rules.passwordLength
    }
  }),
  computed: {
    qsId: function(): number {
      let id = <string>this.$route.query.id;
      if (!id) return null;
      if (isNaN(parseInt(id))) return null;
      return parseInt(id);
    },
    qsKey: function(): string {
      return <string>this.$route.query.key;
    }
  },
  created: function() {
    G.data.customTitle = "Reset Password MeInOne";
  },
  mounted: function() {
    if (!this.qsId || !this.qsKey) {
      // No Activation Credentials
      G.hideLoading();
      this.$router.push({
        name: "Home"
      });
      G.hideLoading();
    }
  },
  methods: {
    resetPassword: function() {
      let form = <any>this.$refs.form;
      let valid = form.validate();
      if (!valid) return;
      G.showLoading("Resetting your password, please wait...", true);
      let qsId = this.qsId;
      let qsKey = this.qsKey;
      accounts
        .resetPassword(qsId, qsKey, this.newPassword)
        .then(lgn => {
          this.$router.push({
            name: "Home"
          });
          G.showSnackbar("Welcome back...", "info");
          Tracking.userId.set(lgn.id);
          Tracking.accounts.passwordReset();
        })
        .catch(err => {
          G.log.error("Account.Reset.resetPassword", {
            error: err,
            qsId,
            qsKey
          });
          G.showSnackbar(err, "error");
        })
        .finally(() => {
          G.hideLoading();
        });
    }
  }
};
