var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { padding: "32px 16px" },
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        {
          attrs: {
            column: "",
            wrap: "",
            "align-stretch": "",
            "justify-space-between": ""
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs10: "", "text-xs-right": "" } }, [
                    _c("h3", { staticClass: "fun-font txext-center" }, [
                      _vm._v(
                        "Your next job opportunity could be a couple of taps away"
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs2: "", "text-xs-right": "" } },
                    [
                      _c("mi1-logo", {
                        attrs: {
                          "css-style": "width:20%",
                          "css-class": "xtop-logo",
                          shadow: 3,
                          "fill-color": "white",
                          "border-width": 0
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          false
            ? _c(
                "div",
                [
                  _c(
                    "v-card",
                    { attrs: { dense: "", light: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticStyle: { padding: "8px 16px" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "right",
                              on: {
                                click: function($event) {
                                  _vm.preview.expanded = !_vm.preview.expanded
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.preview.expanded
                                    ? "expand_less"
                                    : "expand_more"
                                )
                              )
                            ]
                          ),
                          _vm._v("Recent Jobs\n          "),
                          _c(
                            "v-slide-y-transition",
                            [
                              _vm.preview.expanded
                                ? _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent",
                                      staticStyle: { margin: "0 -16px" },
                                      attrs: { "xtwo-line": "", dense: "" }
                                    },
                                    [
                                      _vm._l(_vm.preview.recentJps, function(
                                        jp,
                                        index
                                      ) {
                                        return [
                                          _c(
                                            "v-list-tile",
                                            { key: index, staticClass: "s" },
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                {
                                                  staticStyle: { padding: "0" }
                                                },
                                                [
                                                  _c("v-list-tile-title", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        jp.title
                                                      )
                                                    }
                                                  }),
                                                  _c("v-list-tile-sub-title", [
                                                    _c("small", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "primary--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              jp.organization
                                                                .name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                        —\n                        " +
                                                          _vm._s(
                                                            jp.industry.title
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { padding: "0 32px" } },
            [
              !_vm.saved
                ? [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        model: {
                          value: _vm.formValid,
                          callback: function($$v) {
                            _vm.formValid = $$v
                          },
                          expression: "formValid"
                        }
                      },
                      [
                        _c("v-subheader", [_vm._v("I am...")]),
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "pr-2",
                                attrs: {
                                  xs7: _vm.isSeekerMode,
                                  xs5: !_vm.isSeekerMode
                                }
                              },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: "",
                                      color: _vm.isSeekerMode
                                        ? "white"
                                        : "grey",
                                      "text-color": _vm.isSeekerMode
                                        ? "primary"
                                        : "grey",
                                      outline: !_vm.isSeekerMode
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.isSeekerMode = true
                                      }
                                    }
                                  },
                                  [_vm._v("Looking for a job")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "pl-2",
                                attrs: {
                                  xs7: !_vm.isSeekerMode,
                                  xs5: _vm.isSeekerMode
                                }
                              },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: "",
                                      color: _vm.isSeekerMode
                                        ? "grey"
                                        : "white",
                                      "text-color": _vm.isSeekerMode
                                        ? "grey"
                                        : "primary",
                                      outline: _vm.isSeekerMode
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.isSeekerMode = false
                                      }
                                    }
                                  },
                                  [_vm._v("Hiring")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("br"),
                        _c("v-text-field", {
                          attrs: {
                            required: "",
                            label: "First Name",
                            type: "first",
                            rules: [
                              _vm.rules.required,
                              _vm.rules.maxCounter(50)
                            ],
                            maxlength: "50",
                            color: "white"
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.register($event)
                            },
                            blur: _vm.tests
                          },
                          model: {
                            value: _vm.usrFirstname,
                            callback: function($$v) {
                              _vm.usrFirstname = $$v
                            },
                            expression: "usrFirstname"
                          }
                        }),
                        _c("v-text-field", {
                          attrs: {
                            required: "",
                            label: "Login Email",
                            type: "email",
                            rules: [
                              _vm.rules.required,
                              _vm.rules.email,
                              _vm.rules.maxCounter(150)
                            ],
                            maxlength: "150",
                            hint:
                              "After email verification, you will use this email to login",
                            "persistent-hint": "",
                            color: "white"
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.register($event)
                            }
                          },
                          model: {
                            value: _vm.usrEmail,
                            callback: function($$v) {
                              _vm.usrEmail = $$v
                            },
                            expression: "usrEmail"
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "v-slide-y-transition",
                [
                  _vm.saved
                    ? [
                        _c("h3", [
                          _c("p", [
                            _vm._v(
                              "\n              You just started something amazing\n              "
                            ),
                            _c("strong", { staticClass: "accent--text" }, [
                              _vm._v(_vm._s(_vm.usrFirstname))
                            ]),
                            _vm._v(
                              ",\n              and you are in for a treat.\n            "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "\n              We sent you an activation email in your\n              "
                            ),
                            _c("strong", { staticClass: "accent--text" }, [
                              _vm._v(_vm._s(_vm.usrEmail))
                            ]),
                            _vm._v(
                              " inbox, follow the email\n              instructions, we'll see you on the other side.\n            "
                            )
                          ]),
                          _c("p", [
                            _c("small", { staticClass: "warning--text" }, [
                              _vm._v(
                                "* Can't find the email? check your spam/junk folder."
                              )
                            ])
                          ])
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            2
          ),
          _c(
            "div",
            { staticStyle: { padding: "0 32px" } },
            [
              !_vm.saved
                ? [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          xdisabled: "!currentStepFormIsValid",
                          large: "",
                          color: "accent",
                          outline: "",
                          round: ""
                        },
                        on: { click: _vm.register }
                      },
                      [_vm._v("Sign up")]
                    ),
                    _c(
                      "v-layout",
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-btn", {
                              attrs: {
                                to: "/login",
                                flat: "",
                                color: "white",
                                round: ""
                              },
                              domProps: { textContent: _vm._s("Login") }
                            })
                          ],
                          1
                        ),
                        _c("v-flex", {
                          attrs: { xs6: "", "text-xs-right": "" }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c("br")
            ],
            2
          )
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            "xmulti-line": "true",
            timeout: 4000,
            "auto-height": "",
            bottom: "",
            value: _vm.loginError,
            color: "error"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.loginError) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { cxolor: "pink", fab: "", flat: "" },
              on: {
                click: function($event) {
                  _vm.loginError = null
                }
              }
            },
            [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }