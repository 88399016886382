



































































































































































































































































import Vue from "vue";
import { accounts } from "@/code/api.accounts";
import * as models from "@/models";
import { G, rules } from "@/globals";
import dotSteps from "@/components/dot-steps.vue";
import { lookups } from "@/code/api.lookups";
// import TreeSelect from "@riophae/vue-treeselect"; //https://vue-treeselect.js.org/
import { Tracking } from "@/code/tracking";

export default {
  name: "Accounts_Activate",
  components: { dotSteps },
  data: () => ({
    currentStepIndex: 1,
    frmStepPasswordValid: false,

    stepsMap: {
      account: 1,
      personal: 2,
      business: 3
    },
    activationData: {
      password: "",
      showPassword: false,
      firstname: "",
      lastname: "",
      city: null,
      business: {
        industry: null,
        name: null
      },
      seeker: {
        headline: null,
        currentlyOpen: true,
        jobTypes: [],
        // industry: {'parent':{'parent':null,'children':[],'id':10,'title':'F&B','nodeLevel':1},'children':[],'id':13,'title':'F&B','nodeLevel':1},
        industry: null,
        languages: []
      }
    },
    login: <models.login>null,
    saving: false,
    industries: [],
    rules: {
      required: rules.required,
      maxCounter: rules.maxCounter,
      passwordLength: rules.passwordLength,
      requiredConditional: active => (active ? rules.required : () => true),
      maxCounterConditional: active => (active ? rules.maxCounter : () => true),
      passwordLengthConditional: active =>
        active ? rules.passwordLength : () => true
    },
    countries: <models.country[]>[],
    selectedCountry: <models.country>null,
    cities: <models.city[]>[],
    selectedIndustry: <models.industry>null,
    languages: <models.language[]>[]
  }),
  computed: {
    stepsMeta: function() {
      return [
        {
          index: 1,
          name: "Account",
          title: G.data.isSeekerMode
            ? "Few details left before you record your one minute video"
            : "Few details left before you start",
          subtitle: "First, let's secure you",
          formRef: "accountForm"
        },
        {
          index: 2,
          name: "Personal",
          title: "You...",
          subtitle: G.data.isSeekerMode
            ? "You will get job recommendations based on the location select."
            : "Your name",
          formRef: "personalForm"
        },
        {
          index: 3,
          name: "Business",
          title: G.data.isSeekerMode
            ? "Finally, tell us about your career"
            : "Business",
          subtitle: `${
            G.data.isSeekerMode
              ? "You will get job recommendations based on the industry you select."
              : "Organization information"
          }<br>* You can update your profile information later.`,
          formRef: "businessForm"
        }
      ];
    },
    qsId: function(): number {
      let id = <string>this.$route.query.id;
      if (!id) return null;
      if (isNaN(parseInt(id))) return null;
      return parseInt(id);
    },
    qsKey: function(): string {
      return <string>this.$route.query.key;
    },
    currentStep: function() {
      return this.getStepByIndex(this.currentStepIndex);
    },
    currentStepIsFirst: function(): boolean {
      return this.currentStepIndex == 1;
    },
    currentStepIsLast: function(): boolean {
      return this.currentStepIndex == this.stepsMeta.length;
    },
    hasSelectedCountry: function(): boolean {
      //assume that country selector is in single choice mode
      return !!this.selectedCountry;
    },
    sectors: function() {
      return this.selectedIndustry ? this.selectedIndustry.children : [];
    }

    // stepRules: function() {
    //   return {
    //     step1: [
    //       () =>
    //         this.stepperStep <= 1 ||
    //         this.rules.passwordLength(this.activationData.password)
    //     ],
    //     step2: [
    //       () =>
    //         this.stepperStep <= 2 ||
    //         (!!this.activationData.firstname &&
    //           !!this.activationData.lastname &&
    //           !!this.activationData.city)
    //     ]
    //   };
    // }
  },
  watch: {
    selectedCountry(country) {
      this.cities = [];
      this.activationData.city = null;
      if (country) {
        lookups.cities(country.code).then(response => {
          this.cities = response.cities;
        });
      }
    },
    selectedIndustry(industry) {
      this.activationData.seeker.industry = null;
    }
  },
  created: function() {
    G.data.customTitle = "Activate MeInOne";
    G.showLoading("Preparing your account...", true);

    Tracking.accounts.activateStart();
    G.data.customTitle = "Activate";
    lookups
      .industries()
      .then(
        inds => (this.industries = inds)
        // (this.industries = inds.map(
        //   i => <models.lookupItem>{ id: i.id, name: i.title }
        // ))
      )
      .catch(e => {
        console.error(e);
        // this.registerError = e;
      })
      .finally(() => {
        // this.saving = false;
      });

    lookups
      .countries()
      .then(countries => {
        this.countries = countries;
      });

    lookups
      .languages_Cached()
      .then(languages => {
        this.languages = languages;
      })
      .catch(error => {
        console.log(error);
      });
  },
  mounted: function() {
    // console.log(this.$route.query);
    // G.hideLoading();
    // if (1==1) return; // BUG FOR AMIGO
    if (!this.qsId || !this.qsKey) {
      // No Activation Credentials
      G.hideLoading();

      // this.$router.push({
      //   name: "Home"
      // });
    } else {
      // Get Login Record
      G.setSeekerMode(this.$route.query.r != "1", this, true);

      accounts
        .getLoginForActivation(this.qsId, this.qsKey)
        .then(lgn => {
          this.login = lgn;
          this.activationData.firstname = lgn.firstname;
          Tracking.userId.set(lgn.id);
        })
        .catch(err => {
          G.log.error("Account.Activate.getLoginForActivation", {
            error: err,
            id: this.qsId,
            key: this.qsKey
          });
        })
        .finally(() => {
          G.hideLoading();
        });
    }
  },
  methods: {
    getCurrentStepForm: function(): any {
      return this.$refs[this.currentStep.formRef];
    },

    getStepByIndex: function(index: number) {
      return this.stepsMeta.find(stp => stp.index == index);
    },
    stepContinue: function() {
      let frm = this.getCurrentStepForm();
      // if (!frm) return;
      let fValid = frm.validate();
      if (!fValid) return;

      if (this.currentStepIsLast) {
        this.activateAccount();
      } else {
        this.currentStepIndex++;
      }
    },
    setNewUserMode: function(isSeeker: boolean) {
      G.setSeekerMode(isSeeker, this, true);
    },

    activateAccount: async function() {
      // let valid = this.$refs.form.validate();
      // console.log(valid);

      // if (!valid) {
      //   G.showSnackbar(
      //     "Ooops, I think you missed a field or more, all fields are required...",
      //     "error"
      //   );
      //   return;
      // }
      // Activate
      G.showLoading("Activating your account, please wait...", true);

      let result = G.data
        .isSeekerMode /* I DON'T KNOW HOW IT WORKED WITH THIS VALUE: this.isSeekerMode*/
        ? accounts.activateSeeker({
            id: this.qsId,
            key: this.qsKey,
            firstname: this.activationData.firstname,
            lastname: this.activationData.lastname,
            password: this.activationData.password,
            cityId: this.activationData.city.id,
            headline: this.activationData.seeker.headline,
            industryId: this.activationData.seeker.industry.id,
            currentlyOpen: this.activationData.seeker.currentlyOpen,
            jobTypes: this.activationData.seeker.jobTypes,
            languages: this.activationData.seeker.languages.map(l => l.code)
          })
        : accounts.activateRecruier({
            id: this.qsId,
            key: this.qsKey,
            firstname: this.activationData.firstname,
            lastname: this.activationData.lastname,
            password: this.activationData.password,
            cityId: this.activationData.city.id,
            organizationName: this.activationData.business.name,
            industryId: this.activationData.business.industry.id
          });

      result
        .then(lg => {
          this.$router.push({
            name: "Home"
          });
          G.showSnackbar(
            G.data.isSeekerMode
              ? "You're in, now record your 1' video to apply for jobs, and get discovered by recruiters."
              : "Great, now you can post jobs and discover talent.",
            "success"
          );
          window.location.href = window.location.href + "#activated";
          Tracking.accounts.activateFinish();
          G.data.customTitle = "Activated";
        })
        .catch(err => {
          G.log.error("Account.Activate.activate", {
            error: err,
            id: this.qsId,
            key: this.qsKey
          });
        })
        .finally(() => {
          G.hideLoading();
        });
    }
  }
};
