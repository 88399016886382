var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { padding: "64px 32px" },
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        {
          attrs: {
            column: "",
            wrap: "",
            "align-stretch": "",
            "justify-space-between": ""
          }
        },
        [
          _c(
            "div",
            [
              _c("mi1-logo", {
                attrs: {
                  "css-class": "top-logo",
                  shadow: 3,
                  "fill-color": "white",
                  "border-width": 0
                }
              }),
              _c("h1", { staticClass: "fun-font text-center" }, [
                _vm._v("Cool, let's get you back in...")
              ])
            ],
            1
          ),
          _c(
            "div",
            [
              [
                _c(
                  "v-form",
                  {
                    ref: "form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.resetPassword($event)
                      }
                    },
                    model: {
                      value: _vm.formValid,
                      callback: function($$v) {
                        _vm.formValid = $$v
                      },
                      expression: "formValid"
                    }
                  },
                  [
                    _c("v-text-field", {
                      attrs: {
                        color: "white",
                        xplaceholder: "First, let's secure you",
                        "append-icon": _vm.showPassword
                          ? "visibility_off"
                          : "visibility",
                        rules: [_vm.rules.required, _vm.rules.passwordLength],
                        type: _vm.showPassword ? "text" : "password",
                        label: "New Password",
                        hint: "At least 8 characters",
                        counter: "",
                        maxlength: "24"
                      },
                      on: {
                        "click:append": function($event) {
                          _vm.showPassword = !_vm.showPassword
                        }
                      },
                      model: {
                        value: _vm.newPassword,
                        callback: function($$v) {
                          _vm.newPassword = $$v
                        },
                        expression: "newPassword"
                      }
                    })
                  ],
                  1
                )
              ]
            ],
            2
          ),
          _c(
            "div",
            [
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      block: "",
                      large: "",
                      color: "accent",
                      outline: "",
                      round: ""
                    },
                    on: { click: _vm.resetPassword }
                  },
                  [_vm._v("Reset")]
                ),
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "text-xs-center": "" } },
                      [
                        _c("br"),
                        _c("v-btn", {
                          attrs: {
                            to: "/login",
                            flat: "",
                            color: "white",
                            round: ""
                          },
                          domProps: { textContent: _vm._s("Login") }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              _c("br")
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }